.App {
  text-align: center;
}

.hide {
  display: none;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}
.social-icons a{
    width: 15px !important;
    height: 15px !important;
}
.form-input-phone input {
  background-color: initial !important;
  border: none !important;
}

.form-textarea {
  border: 1px solid #cbd5e1 !important
}
.edit-page-card .card {
  min-height: 474px;
}
.Demo-prioritySelectorItem{ 
  padding-left: 1px !important;
}
.banck-card .card {
  min-height: 240px;
}
.footer{
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}
.MuiTable-root.TicksLayout-table.Layout-ticks{
  display: none !important;
}
.Layout-timeScaleContainer {
  width: 62px !important;
}

.MainLayout-dayScaleEmptyCell {
 
  min-width: 62.6667px !important;
}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell.Cell-horizontalCell
{
border: none !important;

}
.MainLayout-stickyElement.MainLayout-header  tr.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell.Cell-brightRightBorder{
 
  border: none !important;
}
.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-dayScaleEmptyCell.MainLayout-ordinaryLeftPanelBorder{
  border: none !important;
}
main.Day .Cell-dayView{
  display: none !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.Switcher-inputRoot.MuiInputBase-colorPrimary{
  border-radius: 25px !important;
   min-width: 187px;
   background-color: white !important;
}
.select-branch-incalendar {
  position: absolute;
  z-index: 999;

  /* background: white; */
}
 
.MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before {
display: none !important;

}
.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before :before{
  display: none !important;

}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.Toolbar-toolbar .Root-root, .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.Toolbar-toolbar button{
  margin: 0px !important;
    background-color: #ffffff;
    box-shadow: none;
    border: none;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.Toolbar-toolbar .Root-root{
  padding: 6px !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left: 30rem !important;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.Toolbar-toolbar button{
   border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 16px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  background-color: initial !important;
}

label+.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  margin-top: 0px !important;
  /* background: white; */
  border-radius: 20px;
  padding: 0px 4px 4px; 
}
 
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root { 
  line-height: 1em; 
  padding-left: 12px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:before{
 display: none !important;
}
#demo-simple-select-standard-label {
  color: #000;
  top: -14px;
  left: 5px;
}
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  font-size: 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
.Demo-priorityText {
  position: relative;
  left: -5px;
}
.Layout-flexRow  .Label-text { 
  font-weight: bold !important; 
  font-size: 13px !important;
  letter-spacing: 0px !important;
  font-family: 'Inter', sans-serif !important;
}
/* .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Layout-cell .Label-label:nth-child(odd){
  display: none;
} */

.MuiTable-root.Table-table.css-v0nkty-MuiTable-root{
  box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.36);
  -webkit-box-shadow: 10px 3px 9px -2px rgba(0,0,0,0.36);
  -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.36);
  
}
.MainLayout-flexRow.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-ordinaryLeftPanelBorder{
  /* same show on right side  */
  box-shadow: 2px 8px 9px -2px rgba(0,0,0,0.36);
  -webkit-box-shadow: 2px 9px 9px -2px rgba(0,0,0,0.36);
  -moz-box-shadow: 2px 8px 9px -2px rgba(0,0,0,0.36);
}
.MuiTable-root.Table-table.css-nxsm2j{
  -webkit-box-shadow: 2px 3px 8px -2px rgba(0,0,0,.36);
  -moz-box-shadow: 2px 8px 9px -2px rgba(0,0,0,.36);
}

.MainLayout-background.MainLayout-inlineFlex.MainLayout-ordinaryHeaderBorder{ 
  border: none !important;
}
.MainLayout-background.MainLayout-inlineFlex.MainLayout-brightHeaderBorder{
  border: none !important;
}

.Demo-flexibleSpace svg, .Switcher-inputRoot svg { 
  right: 17px;
}
 
.select-branch-incalendar .MuiFormControl-root {
    margin-right: auto;
    width: 318px !important; 
    margin: 0;
  }
.MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input:focus{
  background-color: initial !important;
  background: initial !important;
}
.Demo-prioritySelectorItem{
  text-align: left;
  padding-left: 10px;
}
.Demo-prioritySelectorItem, .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding-left: 16px !important;
  text-align: left;
}
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input{
  text-align: left;
  padding-left: 10px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  margin: 0px !important;
}
 
.MainLayout-relativeContainer .Container-container div {
  margin-top: -2px;
  margin-left: -1px;
  padding-left: 2px;
}
.appointmentStatus {
  margin-left: -27px;
  margin-top: -6px;
}

/* new css  */

.select-branch-incalendar svg {
  right: 9px;
  position: absolute;
}

.Demo-titleContainer {
  padding-bottom: 12px !important;
}
