
.steper {
    position: absolute;
    left: -16px;
    background-color: #f8fafc;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border: 3px solid;
    line-height: 20px;
    color: #037aff;
}
.text-red-error {
 color: rgb(246, 66, 66);
 font-weight: 400;
 font-size: 13px;
 position: relative;
 bottom: 2px;

}
button, input, optgroup, select, textarea {
    color: inherit;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.disocunt-input span{
  width: 20px;
  position: relative;
  top: 13px;
}
.disocunt-input {
    display: flex;
    background: white;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 4px 10px;
}
.disocunt-input select {
    width: 100%;
    padding: 11px;
}
.steper-border { 
    padding-left: 56px;
    border-left: dashed;
    border-width: 1.6px;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
}

.calender-wraper{ 
    position: absolute;
    z-index: 9999;
    background: white;
    padding: 20px;
    top: 62px;
    left: 5px;
}

.leading-snug { 
    text-align: left;
}

.tracking-wider {
    letter-spacing: .05em;
    padding-bottom: 20px;
}
.servcie-list{
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 999;
    border: 0.5px solid #e2e2e294; 
    box-shadow: inherit;
}
#appointment-page form {
    font-weight: 500;
}
#dropdownHelper{ 
    position: relative;
}
#appointment-page select{
    outline: none;
    padding: 15px 16px 15px 16px;
}
#appointment-page label {
    height: 63px;
    padding: 2px 0px 10px 4px;
    /* color: black; */
    font-weight: 500;
}
.client-selection-card form label{ 
    margin-top: 13px;
}
textarea{
    border: none;
    outline: none;
}
#appointment-page textarea {
     font-size: 14px !important;
     font-weight: 400 !important;;  
}   
@media (max-width:768px) {
    #appointment-page .container{
        display: block;
    }   
    #appointment-page .mt-20 {
        margin-top: 2rem;
    }
    #appointment-page .p-5 {
        padding: 0.25rem;
    }
    #appointment-page .flex {
        display: block;
    }
    #dropdownHelper .flex {
        display: flex;
    }
    #appointment-page .px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        width: 100% !important;
    }

    #appointment-page select, #appointment-page input {
        outline: none;
        padding: 10px 16px 12px 16px;
        margin-top: 11px;
    }
    #appointment-page .steper-border {
        padding-left: 24px;
    }   
    .container .inputs.w-full.mt-5.col-span-2.py-6.mx-auto {
        margin-left: 0px !important;
    }
    .inputs.mt-12.py-6.mx-auto{ 
        width: 100%;
        margin-top: 0px;
        padding-top: 0; 
    }
    .flex.items-center.justify-center.rounded-l-lg.border{
        display: none;
    }
    #appointment-page .flex {
        margin-top: 13px; 
    }
}